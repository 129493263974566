import React from 'react';
import PropTypes from 'prop-types';

const LogEntries = ({ logEntries }) => {
  return (
    <div className="log-section" style={{ marginTop: '20px', padding: '10px' }}>
      <h2 className="log-title">Past Draws</h2>
      <table className="log-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>PREV DAY</th>
            <th>PREV NITE</th>
          </tr>
        </thead>
        <tbody>
          {logEntries.map((entry, index) => (
            <tr key={index}>
              <td>{entry.date}</td>
              <td>{entry.daySum}</td>
              <td>{entry.niteSum}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

LogEntries.propTypes = {
  logEntries: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      daySum: PropTypes.string.isRequired,
      niteSum: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default LogEntries;
