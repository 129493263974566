import React from 'react';
import './LotteryBooks.css';


const books = [
    {
        title: "Winning the Lottery: Expert Strategies and Proven Techniques for Serious Players",
        image: "https://a.media-amazon.com/images/I/614zpecLUOL._SY342_.jpg",
        link: "/winning-the-lottery"
    },
    {
        title: "How To Make a Living Playing the Lottery: Winning Strategies, Tips, and Financial Secrets for Lottery Success",
        image: "https://a.media-amazon.com/images/I/617xgXQGO7L._SL1293_.jpg",
        link: "/how-to-make-a-living-playing-the-lottery"
    },
    {
        title: "Pick 3 Lottery Book 730 Rundown Method Strategy Workbook: How to Win the Lottery Number Tracker System. Improve You Chances of Winning In Your State",
        image: "https://m.media-amazon.com/images/I/41E8GuhqNRL._SY445_SX342_.jpg",
        link: "/master-730-rundown"
    },
    {
        title: "9-6-2 Method: Pick 3 Lottery Strategy Workbook: Pick 3 Lottery Book",
        image: "https://m.media-amazon.com/images/I/61NmGsrmgkL._SL1499_.jpg",
        link: "/pick-3-962-method"
    },
    {
        title: "Pick 4 Lottery Strategy: 3-6-9-3 Workbook for Effective Number Tracking: Win The Lottery in Any State Pick 4 Lottery Book",
        image: "https://m.media-amazon.com/images/I/61DyQv0m7EL._SL1499_.jpg",
        link: "/pick-4-3693-method"
    },
    {
        title: "Pick 3 Lottery Book: 3-1-7 Rundown Strategy Workbook",
        image: "https://m.media-amazon.com/images/I/61TtWWlNDLL._SL1499_.jpg",
        link: "/pick-3-317-method"
    },
    {
        title: "Super Pick 3 Lottery Workout Workbook: Simple System with Color Coded Daily Predictions",
        image: "https://m.media-amazon.com/images/I/61rt4W9w16L._SL1499_.jpg",
        link: "/super-pick-3-workout"
    },
    {
        title: "3-6-9 Rundown Workbook: Pick 3 Lottery Book",
        image: "https://m.media-amazon.com/images/I/61qpBwTZOJL._SL1499_.jpg",
        link: "/pick-3-369-rundown"
    },
    {
        title: "Pick 4 Lottery Strategy Rundown Number Tracker Workbook: Learn How to Increase Your Chances of Winning the Texas Lottery",
        image: "https://m.media-amazon.com/images/I/61xQhw98p0L._SL1499_.jpg",
        link: "/pick-4-3693-method-texas"
    },
    {
        title: "Pick 4 Lottery Strategy: 3-6-9-3 Workbook for Effective Number Tracking: Win The Lottery in Any State Pick 4 Lottery Book",
        image: "https://m.media-amazon.com/images/I/61mlMTdIo-L._SL1293_.jpg",
        link: "/pick-4-3693-method-large-format"
    },
    {
        title: "Cash 4 Lottery Strategy Workbook: Number Tracking for Winning",
        image: "https://m.media-amazon.com/images/I/61Ms8Ct2UpL._SL1499_.jpg",
        link: "/cash-4-lottery-strategy"
    },
    {
        title: "Pick 3 Mastery: The Lottery Winner's Blueprint: Strategies, Probabilities, and Daily Insights for Consistent Wins",
        image: "https://m.media-amazon.com/images/I/712vba7YcgL._SL1000_.jpg",
        link: "/pick-3-mastery"
    },
    {
        title: "Pick 3 Lottery Strategy Workbook: Pick 3 Lottery Book",
        image: "https://m.media-amazon.com/images/I/619a8qLNiXL._SL1499_.jpg",
        link: "/pick3-lottery-strategy-workbook"
    },
    {
        title: "Cash 4 Lottery Strategy Workbook: Number Tracking for Winning",
        image: "https://m.media-amazon.com/images/I/61KCY+7oU2L._SL1293_.jpg",
        link: "/cash-4-large-format"
    },
    {
        title: "Pick 3 Lottery Strategy Workbook for Effective Number Tracking (Maestría del Pega 3): Win The Lottery in Any State (Desbloqueado Riqueza con Cada Número) (Spanish Edition)",
        image: "https://m.media-amazon.com/images/I/61bFSe62ccL._SL1000_.jpg",
        link: "/pick-3-spanish"
    }
];

const LotteryBooks = () => {
    return (
        <div>
            
            <div className="books-container">
                {books.map((book, index) => (
                    <div className="book-item" key={index}>
                        <div className="book-image-container">
                            <a href={book.link} target="_blank" rel="noopener noreferrer">
                                <img 
                                    src={book.image} 
                                    alt={book.title} 
                                    className="book-image"
                                />
                            </a>
                        </div>
                        <h3 className="book-title">{book.title}</h3>
                        <a href={book.link} className="book-cta-button" target="_blank" rel="noopener noreferrer">Learn More</a>
                    </div>
                ))}
            </div>
            <div className="solutions-section">
                <p>Our range of lottery strategy workbooks offers comprehensive solutions for tracking and analyzing lottery numbers. Whether you're playing Pick 3 or Pick 4, our workbooks provide proven methods and strategies to enhance your chances of winning.</p>
            </div>
            <div className="value-section">
                
                <p>Each workbook is designed to provide significant value to lottery players by offering detailed tracking systems, strategy rundowns, and color-coded daily predictions. By using these workbooks, players can increase their understanding of lottery patterns and improve their odds of winning consistently.</p>
            </div>
        </div>
    );
};

export default LotteryBooks;
