import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Cancel = () => {
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // Trigger redirect after 3 seconds
    const timer = setTimeout(() => {
      navigate('/'); // Redirect to the home page (Pick4Generator)
    }, 3000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div>
      <h1>Payment Cancelled</h1>
      <p>Your payment was canceled. You will be redirected back to the generator shortly.</p>
    </div>
  );
};

export default Cancel;
