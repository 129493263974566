import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig';
import './Paywall.css';  // Fixed the typo

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await signOut(auth);
        // Redirect to login page after successful logout
        navigate('/login');
      } catch (error) {
        console.error('Error logging out: ', error);
      }
    };

    logoutUser();
  }, [navigate]);

  return (
    <div className="paywall-container">
      <h2>Logging out...</h2>
      {/* Optionally add a spinner or a message here */}
      <div className="spinner-container">
        <div className="spinner"></div> {/* Add spinner class in CSS */}
      </div>
    </div>
  );
}

export default Logout;
