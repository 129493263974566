import React from 'react';
import Helmet from 'react-helmet';
import './HomePage.css';

const HomePage = () => (
  <div>
    <Helmet>
      <title>L Numeris Lottery Strategies - Home</title>
      <meta name="description" content="Explore winning lottery strategies for Pick 3, Pick 4, and more. Find comprehensive guides and tools to increase your chances." />
    </Helmet>

    <div className="generator-container homepage">
      <h1>Welcome to Inumeris Lottery Strategies</h1>
      <p>Explore our lottery strategy tools and guides below:</p>

      <div className="image-links combinations-grid">
        <a href="/pick-3" className="image-container">
          <img src="/images/Pick3ComboGenerator.png" alt="Pick 3 Combo Generator" className="combination-block scaled-image" />
          <div className="description">
            <h3>Pick 3 Combo Generator</h3>
            <p>Generate powerful combinations for the Pick 3 lottery game.</p>
          </div>
        </a>
        <a href="/pick-4" className="image-container">
          <img src="/images/Pick4ComboGenerator.png" alt="Pick 4 Combo Generator" className="combination-block scaled-image" />
          <div className="description">
            <h3>Pick 4 Combo Generator</h3>
            <p>Create winning combinations for the Pick 4 lottery game.</p>
          </div>
        </a>
        <a href="/statepick3gen" className="image-container">
          <img src="/images/StatePick3Gen.png" alt="State Pick 3 Generator" className="combination-block scaled-image" />
          <div className="description">
            <h3>State Pick 3 Generator</h3>
            <p>Get state-specific combinations for the Pick 3 lottery.</p>
          </div>
        </a>
        <a href="/3693-rundown" className="image-container">
          <img src="/images/3693Rundown.png" alt="3693 Rundown Generator" className="combination-block scaled-image" />
          <div className="description">
            <h3>3693 Rundown Generator</h3>
            <p>Use the 3693 rundown strategy to increase your Pick 3 odds.</p>
          </div>
        </a>
        <a href="/grid4x4" className="image-container">
          <img src="/images/Grid4x4.png" alt="Grid 4x4 Strategy" className="combination-block scaled-image" />
          <div className="description">
            <h3>Grid 4x4 Strategy</h3>
            <p>Utilize the 4x4 grid strategy to visualize and select your numbers.</p>
          </div>
        </a>
        <a href="/730-rundown" className="image-container">
          <img src="/images/730Rundown.png" alt="730 Rundown" className="combination-block scaled-image" />
          <div className="description">
            <h3>730 Rundown</h3>
            <p>Apply the 730 rundown technique to boost your winning chances.</p>
          </div>
        </a>
      </div>
    </div>
  </div>
);

export default HomePage;

