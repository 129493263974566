import React from 'react';

const DateWidget = ({ onDaySumChange }) => {
  const today = new Date();
  const month = today.getMonth() + 1; // getMonth() returns 0-11, so we add 1
  const day = today.getDate();
  const daySum = month + day;
  const daySumSecondDigit = daySum % 10; // Keep only the second digit of the day sum
  const formattedDate = today.toLocaleDateString('en-US'); // Using 'en-US' locale for MM/DD/YYYY format

  // Call the callback function to notify the parent component of the day sum change
  React.useEffect(() => {
    onDaySumChange(daySumSecondDigit);
  }, [daySumSecondDigit, onDaySumChange]);

  return (
    <div className="date-widget">
      <div>{formattedDate}</div>
      <div>Day Sum: {daySumSecondDigit}</div>
    </div>
  );
};

export default DateWidget;
