import React, { useState } from 'react';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig';
import './Auth.css';

function SignInWithEmailLink() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const actionCodeSettings = {
    url: 'http://localhost:3000/finishSignIn',
    handleCodeInApp: true,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setMessage('Sign-in link sent to your email.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div className="auth-container">
      <h2>Sign in with Email Link</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Sign-In Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default SignInWithEmailLink;
