import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { auth, db } from './firebase/firebaseConfig';
import './Pick4Generator.css';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LotteryBooks from './LotteryBooks';

Modal.setAppElement('#root');

const INITIAL_CREDITS = 25;

const CombinationsDisplay = ({ combinations, isAnimating }) => (
  <div className="combinations-to-play">
    <label>Combinations To Play:</label>
    <div className="combinations-grid">
      {combinations.map((combo, index) => (
        <div key={index} className="combination-block">
          <p>Combo / Day Sum {index}:</p>
          <ul>
            {combo.combinations.map((combination, idx) => (
              <li key={idx}>
                {combination.split('').map((digit, digitIndex) => (
                  <span key={digitIndex} className="lotto-digit">{digit}</span>
                ))}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
);

function Pick3Generator() {
  const [initialNumber, setInitialNumber] = useState('');
  const [combinations, setCombinations] = useState([]);
  const [displayedCombinations, setDisplayedCombinations] = useState([]);
  const [error, setError] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [credits, setCredits] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [history, setHistory] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for the confirmation modal
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);
        setUserEmail(user.email);

        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setCredits(userData.credits || 0);
          setHistory(userData.history || []);
          setIsSubscribed(userData.isSubscribed || false);
        } else {
          await setDoc(userRef, { credits: INITIAL_CREDITS, history: [], isSubscribed: false });
          setCredits(INITIAL_CREDITS);
          setHistory([]);
          setIsSubscribed(false);
        }
      } else {
        setIsLoggedIn(false);
        setUserEmail('');
      }
    });

    return () => unsubscribe();
  }, []);

  const generateCombinations = async () => {
    if (initialNumber.length !== 3 || !/^\d{3}$/.test(initialNumber)) {
      setError('Please enter a valid 4-digit number.');
      return;
    }

    if (history.some(item => item.input === initialNumber)) {
      setError('This combination has already been generated.');
      return;
    }

    const user = auth.currentUser;

    if (credits <= 0) {
      navigate('/paywall');
      return;
    }

    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.credits > 0) {
          await updateDoc(userRef, { credits: userData.credits - 1 });
          setCredits(userData.credits - 1);
        } else {
          navigate('/paywall');
          return;
        }
      } else {
        setError('User data not found.');
        return;
      }
    }

    if (!user && credits > 0) {
      setCredits(credits - 1);
    } else if (!user && credits <= 0) {
      navigate('/paywall');
      return;
    }

    let newCombinations = [];
    for (let finalDigit = 0; finalDigit < 10; finalDigit++) {
      let finalCombinations = [];
      for (let i = 0; i < 3; i++) {
        let newCombination = initialNumber.substring(0, i) + finalDigit + initialNumber.substring(i + 1);
        finalCombinations.push(newCombination);
      }
      newCombinations.push({ finalDigit: finalDigit, combinations: finalCombinations });
    }

    setCombinations(newCombinations);
    animateDigits(newCombinations);

    const newHistoryItem = { input: initialNumber, date: new Date().toLocaleDateString() };
    setHistory([newHistoryItem, ...history]);

    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { history: [newHistoryItem, ...history] });
    }
  };

  const clearPlayHistory = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { history: [] });
    }
    setHistory([]);
    closeModal();
  };

  const openModal = () => {
    if (history.length > 0) { // Only open modal if history exists
      setIsModalOpen(true);
    } else {
      setError("No play history to clear.");
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const animateDigits = (newCombinations) => {
    setIsAnimating(true);
    let step = 0;
    const maxSteps = 10;
    const intervalId = setInterval(() => {
      if (step < maxSteps) {
        setDisplayedCombinations(newCombinations.map(combo => ({
          ...combo,
          combinations: combo.combinations.map(combination =>
            combination.split('').map(() => Math.floor(Math.random() * 10).toString()).join('')
          ),
        })));
        step++;
      } else {
        clearInterval(intervalId);
        setIsAnimating(false);
        setDisplayedCombinations(newCombinations);
      }
    }, 100);
  };

  const handleInitialNumberChange = (event) => {
    const value = event.target.value;
    if (value.length <= 3 && /^[0-9]*$/.test(value)) {
      setInitialNumber(value);
      setError('');
    } else {
      setError('Please enter only up to 3 digits.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      generateCombinations();
    }
  };

  const clearInput = () => {
    setInitialNumber('');
    setError('');
    setCombinations([]);
    setDisplayedCombinations([]);
  };

  const handleBuyCredits = () => {
    navigate('/paywall');
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleProfileRedirect = () => {
    navigate('/profile');
  };

  return (
    <div>
      <div className="hero">
      {/* Home Link */}
        <div className="home-link-container">
          <a href="/" className="home-link">
            <i className="fas fa-home"></i>
          </a>
        </div>
        <h1>L. Numeris Pick 3 Combination Generator</h1>
        <p>Generate combinations based on your input</p>

        <div className="credits-display-header">
          <h2>
            {isSubscribed ? `${credits} Credits` : `${credits} Free Credits`}
          </h2>
          {isLoggedIn && <p>Welcome, {userEmail}</p>}
        </div>

        {isLoggedIn && (
          <div className="profile-logout-section">
            <button className="profile-btn" onClick={handleProfileRedirect}>
              Go to Profile
            </button>
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>

      <Tabs>
        <TabList>
          <Tab>Generate Plays</Tab>
          <Tab>Play History</Tab>
          <Tab>How to Use</Tab>
          <Tab>Mirrors & Flip Numbers</Tab>
          <Tab>Resources</Tab>
        </TabList>

        <TabPanel>
          <div className="generator-container">
            <div className="button-container">
              <label>Your Pick:</label>
              <input
                type="tel"
                value={initialNumber}
                onChange={handleInitialNumberChange}
                onKeyDown={handleKeyDown}
                inputMode="numeric"
                pattern="\d*"
              />
              <button onClick={generateCombinations} disabled={credits <= 0 || isAnimating}>
                Generate Combinations
              </button>
              <button className="clear-button" onClick={clearInput}>
                Clear
              </button>
              {error && <p className="error-message">{error}</p>}
              {credits === 0 && (
                <button className="buy-credits-button" onClick={handleBuyCredits}>
                  Buy More Credits
                </button>
              )}
            </div>

            <CombinationsDisplay
              combinations={isAnimating ? displayedCombinations : combinations}
              isAnimating={isAnimating}
            />
          </div>
        </TabPanel>

        <TabPanel>
          <div className="history-container">
            <h2>Your Plays</h2>
            <ul className="history-list">
              {history.map((item, index) => (
                <li key={index} className="history-item">
                  {item.input.split('').map((digit, digitIndex) => (
                    <span key={digitIndex} className="lotto-digit">{digit}</span>
                  ))}
                  <span className="timestamp"> - {item.date}</span>
                </li>
              ))}
            </ul>
            {history.length > 0 && (
              <button className="clear-history-btn" onClick={openModal}>
                Clear Play History
              </button>
            )}
          </div>
        </TabPanel>

        
        
        <TabPanel>
        <div className="how-to-use-container"> {/* Reuse the auth-container class */}
            <h2>How to Use the L. Numeris Pick 3 Combination Generator</h2>
            <ol className="how-to-use-list">  {/* Add a class for custom list styling */}
              <li><strong>Enter Your 3-Digit Number:</strong> In the "Your Pick" box, type in a 3-digit number. You can type it in directly or use the number pad.</li>
              <li><strong>Generate Combinations:</strong> After you enter your number, click the "Generate Combinations" button. The app will create different sets of combinations based on your pick. You can also press the **Enter** key to do this faster.</li>
              <li><strong>Check Your Play History:</strong> Every time you generate combinations, they are saved. You can go to the "Play History" tab to see the numbers you've played before. If you're logged in, your history is saved and will be there next time you log in.</li>
              <li><strong>Credits:</strong>
                <ul className="how-to-use-credits-list">
                  <li>Each time you generate combinations, it uses 1 credit.</li>
                  <li><strong>Credits Run Out?</strong> You will be redirected to the payment page where you can buy more credits.</li>
                </ul>
              </li>
              <li><strong>Clear Your Pick:</strong> You can press the "Clear" button to erase the number you entered and start again. This doesn't use any credits or erase your history.</li>
              <li><strong>Log In for More Options:</strong> When you're logged in, the app will save your play history and let you buy more credits when needed. Make sure to log in so you don't lose your plays.</li>
              <li><strong>Devices:</strong> This app runs on Smartphone,Tablets,Desktops and Laptops. Just navigate to <a href='https://lnumerislotterystrategies.com'>lnumerislotterystrategies.com</a> in your browser and login</li>
              
            </ol>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="how-to-use-container"> {/* Reuse the auth-container class */}
            <h2>How to Use Mirror and Flip Numbers</h2>
            <p>
              Mirror and flip numbers are common strategies used in lottery systems to help you generate alternative combinations based on your original number. Here’s how they work:
            </p>
            <h3>What are Mirror Numbers?</h3>
            <p>
              A mirror number is the "opposite" of a digit. In lottery terms, the mirror of a digit is derived by adding 5 to the original number, with results modulated by 10. For example:
            </p>
            <ul className="mirror-number-list">
              <li>The mirror of <strong>0</strong> is <strong>5</strong>.</li>
              <li>The mirror of <strong>1</strong> is <strong>6</strong>.</li>
              <li>The mirror of <strong>2</strong> is <strong>7</strong>.</li>
              <li>The mirror of <strong>3</strong> is <strong>8</strong>.</li>
              <li>The mirror of <strong>4</strong> is <strong>9</strong>.</li>
              <li>The mirror of <strong>5</strong> is <strong>0</strong>.</li>
              <li>The mirror of <strong>6</strong> is <strong>1</strong>.</li>
              <li>The mirror of <strong>7</strong> is <strong>2</strong>.</li>
              <li>The mirror of <strong>8</strong> is <strong>3</strong>.</li>
              <li>The mirror of <strong>9</strong> is <strong>4</strong>.</li>
            </ul>
            <p>To use mirror numbers, simply convert each digit of your original number to its mirror. For example, if your number is 1234, the mirror version would be 6789.</p>

            <h3>What are Flip Numbers?</h3>
            <p>
              Flip numbers involve shifting each digit up by 3. For example:
            </p>
            <ul className="flip-number-list">
              <li>The flip of <strong>0</strong> is <strong>3</strong>.</li>
              <li>The flip of <strong>1</strong> is <strong>4</strong>.</li>
              <li>The flip of <strong>2</strong> is <strong>5</strong>.</li>
              <li>The flip of <strong>3</strong> is <strong>6</strong>.</li>
              <li>The flip of <strong>4</strong> is <strong>7</strong>.</li>
              <li>The flip of <strong>5</strong> is <strong>8</strong>.</li>
              <li>The flip of <strong>6</strong> is <strong>9</strong>.</li>
              <li>The flip of <strong>7</strong> is <strong>0</strong>.</li>
              <li>The flip of <strong>8</strong> is <strong>1</strong>.</li>
              <li>The flip of <strong>9</strong> is <strong>2</strong>.</li>
            </ul>
            <p>
              Using flip numbers helps generate a new set of combinations by transforming each digit of your original number. For example, if your number is 2468, the flip version would be 5791.
            </p>

            <h3>When to Use Mirror and Flip Numbers</h3>
            <p>
              You can use mirror and flip numbers when you want to increase your chances of hitting a winning combination by covering more number patterns. Both techniques generate variations of your original number to help you explore different possibilities.
            </p>
            <p>
              Try using these techniques next time you generate a combination to see how they work!
            </p>
          </div>
        </TabPanel>
        <TabPanel>
              <LotteryBooks />
        </TabPanel>
  
        
      </Tabs>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Clear History Confirmation"
        className="confirmation-modal"
        overlayClassName="confirmation-modal-overlay"
      >
        <h2>Confirm Clear Play History</h2>
        <p>Are you sure you want to clear your play history? This action cannot be undone.</p>
        <div className="modal-buttons">
          <button onClick={closeModal} className="cancel-btn">Cancel</button>
          <button onClick={clearPlayHistory} className="confirm-btn">Confirm</button>
        </div>
      </Modal>
    </div>
  );
}

export default Pick3Generator;
