import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { auth } from './firebase/firebaseConfig';  // Import Firebase Auth
import './Paywall.css';  // Import the CSS file for styling

// Load Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Paywall = () => {
  const [selectedTier, setSelectedTier] = useState('');
  const [billingCycle, setBillingCycle] = useState('monthly');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Reset loading state when the component mounts
  useEffect(() => {
    setLoading(false);
  }, []);  // This ensures the "Processing..." button resets when the page is revisited

  const handleSelectTier = (tier) => {
    setSelectedTier(tier);
  };

  const handleBillingToggle = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  };

  const handleCheckout = async () => {
    const priceMap = {
      basic: {
        monthly: 'price_1QBXb4I59sBrVN1qM0ab5D3R',  // Replace with your actual Stripe price ID for Basic (Monthly)
        yearly: 'price_1QCBSXI59sBrVN1qLwFQWjnh',    // Replace with your actual Stripe price ID for Basic (Yearly)
      },
      pro: {
        monthly: 'price_1QBXb1I59sBrVN1qM3h1YZJm',    // Replace with your actual Stripe price ID for Pro (Monthly)
        yearly: 'price_1QCBTXI59sBrVN1qVe0R7PuG',      // Replace with your actual Stripe price ID for Pro (Yearly)
      },
      premium: {
        monthly: 'price_1QBXaxI59sBrVN1qPEc3d2sV', // Replace with your actual Stripe price ID for Premium (Monthly)
        yearly: 'price_1QCBU8I59sBrVN1qv2nrzxPh',   // Replace with your actual Stripe price ID for Premium (Yearly)
      },
    };

    const priceId = priceMap[selectedTier]?.[billingCycle];

    if (!priceId) {
      alert('Please select a subscription tier.');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const user = auth.currentUser;  // Fetch the current authenticated user
      if (!user) {
        setError('User not authenticated. Please log in.');
        setLoading(false);
        return;
      }

      const userId = user.uid;  // Get the Firebase user ID

      const backendUrl =
        process.env.NODE_ENV === 'production'
          ? '/.netlify/functions/create-checkout-session'
          : 'http://localhost:8888/.netlify/functions/create-checkout-session';

      // Send priceId and userId to the backend
      const { data } = await axios.post(backendUrl, { priceId, userId });

      const stripe = await stripePromise;

      // Redirect to Stripe Checkout using the sessionId
      const result = await stripe.redirectToCheckout({
        sessionId: data.id,
      });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      setError(`Error: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoading(false);  // Reset loading state after the checkout process
    }
  };

  return (
    <div className="paywall-container">
      <h1>Select Your Subscription</h1>

      {/* Billing cycle toggle (monthly/yearly) */}
      <div className="billing-toggle">
        <label className={`toggle-label ${billingCycle === 'monthly' ? 'active' : ''}`}>
          Monthly
        </label>
        <label className="toggle-switch">
          <input type="checkbox" checked={billingCycle === 'yearly'} onChange={handleBillingToggle} />
          <span className="slider round"></span>
        </label>
        <label className={`toggle-label ${billingCycle === 'yearly' ? 'active' : ''}`}>
          Yearly (Save 20%)
        </label>
      </div>

      {/* Subscription tier buttons */}
      <div className="paywall-plans">
        <div className={`plan-card ${selectedTier === 'basic' ? 'selected' : ''}`} onClick={() => handleSelectTier('basic')}>
          <h2>Basic Plan</h2>
          <p className="price">{billingCycle === 'monthly' ? '$10/month' : '$100/year'}</p>
          <ul>
            <li>{billingCycle === 'monthly' ? '100 combinations' : '1320 combinations (with bonus)'}</li>
            <li>Email support</li>
            <li>Access to Pick 3 Generator</li>
            <li>Access to Number Grid Generators</li>
          </ul>
          {billingCycle === 'yearly' && <p className="bonus">+ Get 12 months for the price of 10!</p>}
        </div>

        <div className={`plan-card ${selectedTier === 'pro' ? 'selected' : ''}`} onClick={() => handleSelectTier('pro')}>
          <h2>Pro Plan</h2>
          <p className="price">{billingCycle === 'monthly' ? '$20/month' : '$200/year'}</p>
          <ul>
            <li>{billingCycle === 'monthly' ? '300 combinations' : '3960 combinations (with bonus)'}</li>
            <li>Priority email support</li>
            <li>Access to Pick 3 and Pick 4 Generators</li>
            <li>Exclusive strategy tips and guides</li>
          </ul>
          {billingCycle === 'yearly' && <p className="bonus">+ Get 12 months for the price of 10!</p>}
        </div>

        <div className={`plan-card ${selectedTier === 'premium' ? 'selected' : ''}`} onClick={() => handleSelectTier('premium')}>
          <h2>Premium Plan</h2>
          <p className="price">{billingCycle === 'monthly' ? '$30/month' : '$300/year'}</p>
          <ul>
            <li>Unlimited combinations</li>
            <li>Dedicated support</li>
            <li>Access to Pick 3, Pick 4, and Number Grid Generators</li>
            <li>Special strategy webinars and live sessions</li>
          </ul>
          {billingCycle === 'yearly' && <p className="bonus">+ Free access to exclusive webinars!</p>}
        </div>
      </div>

      <button className="checkout-btn" onClick={handleCheckout} disabled={loading}>
        {loading ? 'Processing...' : 'Proceed to Checkout'}
      </button>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Paywall;
