import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig';

function FinishSignIn() {
  const navigate = useNavigate();

  useEffect(() => {
    const finishSignIn = async () => {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          await signInWithEmailLink(auth, email, window.location.href);
          window.localStorage.removeItem('emailForSignIn');
          navigate('/');
        } catch (error) {
          console.error('Error signing in with email link', error);
        }
      }
    };

    finishSignIn();
  }, [navigate]);

  return (
    <div>
      <h2>Finishing sign-in...</h2>
    </div>
  );
}

export default FinishSignIn;
