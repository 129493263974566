import React from 'react';
import './TermsOfService.css';

const TermsOfService = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Terms of Service</h1>
      <p>Last updated: October 2024</p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By using our service, you are agreeing to comply with and be bound by the following terms and conditions. 
        Please review the following terms carefully. If you do not agree to these terms, you should not use this site. 
        Continued use of this site after any changes to these terms constitutes acceptance of those changes.
      </p>

      <h2>2. Changes to the Terms</h2>
      <p>
        We reserve the right to modify or replace these Terms of Service at any time. If a revision is material, 
        we will try to provide at least 30 days' notice before any new terms take effect. What constitutes a material change 
        will be determined at our sole discretion. You will be notified of any material changes via email or via a 
        notice on our website. Continued use of the service after the changes are effective constitutes your acceptance.
      </p>

      <h2>3. Accounts</h2>
      <p>
        When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. 
        Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.
        You are responsible for safeguarding the password that you use to access the service and for any activities or actions 
        under your password, whether your password is with our service or a third-party service. 
        You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
      </p>

      <h2>4. Payment and Billing</h2>
      <p>
        By subscribing to our service, you agree to pay the subscription fees indicated for that service. 
        Payment is due in advance for each billing cycle, and your subscription will automatically renew unless canceled.
        We accept payment methods that include [insert payment methods]. 
        If your payment method fails, we reserve the right to suspend or terminate your access to the service.
      </p>

      <h2>5. Cancellations and Refunds</h2>
      <p>
        You may cancel your subscription at any time. However, there are no refunds for partial billing cycles or unused services.
        In the event of termination, you will still have access to the service until the end of the current billing cycle.
      </p>

      <h2>6. User Conduct</h2>
      <p>
        You agree not to engage in any activity that disrupts or interferes with our service. 
        Any attempt to gain unauthorized access to our systems or other accounts may result in termination of your access.
        You are strictly prohibited from using the service for illegal activities, spamming, harassment, or intellectual property violations.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        We are not liable for any damages that may arise from your use of our service, including but not limited to direct, indirect, incidental, 
        or punitive damages. Our total liability for any claim arising out of or relating to these terms is limited to the amount 
        you have paid for the service.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions. 
        Any legal action or dispute arising from these Terms will be handled in the courts of [Your Jurisdiction].
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:support@lnumerislotterystrategies.com">support@lnumerislotterystrategies.com</a>.
      </p>

      <h2>10. Intellectual Property</h2>
      <p>
        All content, logos, and trademarks on this website are the property of [Your Company Name]. 
        You are not allowed to copy, distribute, or modify any content without our explicit permission.
      </p>

      <h2>11. Termination of Accounts</h2>
      <p>
        We reserve the right to terminate or suspend your account for violating these Terms or engaging in any activity that may harm our service or other users. 
        We also reserve the right to pursue legal action for violations.
      </p>

      <h2>12. Dispute Resolution</h2>
      <p>
        Any dispute arising from these terms will first be addressed through negotiation. 
        If unresolved, it will be submitted to binding arbitration in accordance with the rules of [Arbitration Rules], with any court action being a last resort.
      </p>

      <h2>13. Force Majeure</h2>
      <p>
        We are not liable for any failure or delay in performance due to circumstances beyond our control, including natural disasters, strikes, and technical failures.
      </p>
    </div>
  );
};

export default TermsOfService;
