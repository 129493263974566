import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase/firebaseConfig'; // Firebase setup
import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore'; // Firestore methods
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import './Profile.css'; // CSS for the profile page

function Profile() {
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for routing

  // Fetch the Stripe Customer ID from Firestore when the component mounts
  useEffect(() => {
    const fetchCustomerId = async () => {
      const user = auth.currentUser;

      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid); // Firestore document for the user
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            setStripeCustomerId(userData.stripeCustomerId); // Store the stripeCustomerId in state
          } else {
            setError('User data not found in Firestore.');
          }
        } catch (err) {
          setError('Failed to fetch user data.');
        }
      }
    };

    fetchCustomerId();
  }, []);

  // Function to handle managing the subscription via Stripe's customer portal
  const handleManageSubscription = async () => {
    if (!stripeCustomerId) {
      setError('Stripe Customer ID not available.');
      return;
    }

    try {
      setLoading(true);
      const user = auth.currentUser;

      if (user) {
        const idToken = await user.getIdToken(); // Retrieve Firebase ID token for secure request
        const response = await axios.post(
          '/.netlify/functions/create-portal-session',
          { customerId: stripeCustomerId }, // Send the Stripe customer ID
          {
            headers: {
              Authorization: `Bearer ${idToken}`, // Secure the request using Firebase ID token
            },
          }
        );

        // Redirect the user to the Stripe Customer Portal
        window.location.href = response.data.url;
      }
    } catch (error) {
      setError('Error redirecting to Stripe Customer Portal.');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-container">
      <h1>Your Profile</h1>

      {/* Section to explain what this page is for */}
      <div className="profile-info">
        <h2>What Can You Do Here?</h2>
        <p>
          This page allows you to manage your subscription and personal information.
          You can view or update your subscription details, change payment methods,
          or cancel your subscription if needed.
        </p>
        <p>
          If you have an active subscription, you can click the button below to manage
          it through our secure Stripe Customer Portal.
        </p>
        <p>
          Please note that by clicking the "Manage Subscription" button, you will be redirected
          to Stripe's customer portal where you can handle all aspects of your subscription
          securely.
        </p>
      </div>

      {error && <p className="error-message">{error}</p>}

      {/* Manage subscription button */}
      <button onClick={handleManageSubscription} disabled={loading || !stripeCustomerId}>
        {loading ? 'Loading...' : 'Manage Subscription'}
      </button>

      {/* Optional Sign Up button */}
      <button onClick={() => navigate('/')} className="secondary-button">
       back
      </button>
    </div>
  );
}

export default Profile;
