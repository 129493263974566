import React from 'react';
import LotteryGen from './LotteryGen';
import LotteryBooks from './LotteryBooks';
import './LotteryBooks.css'


const states = ["",
    "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", 
    "Florida", "Georgia", "Illinois", "Indiana", "Iowa", "Kentucky", "Louisiana", 
    "Maryland", "Michigan", "Missouri", "New Jersey", "New York", 
    "North Carolina", "Ohio", "Oklahoma", "Pennsylvania", "South Carolina", 
    "Tennessee", "Texas", "Virginia", "Washington D.C.", "West Virginia"
  ];
  
  const statesWithFireball = [
    "Delaware", "Florida", "Georgia", "Illinois", "Maryland", 
    "Missouri", "New Jersey", "North Carolina", "Ohio", 
    "Pennsylvania", "Tennessee", "Texas", "Virginia"
  ];
  

  const Pick3StateGenerator = () => {
    return (
      
      <>
      {/* Home Link */}
      <div className="home-link-container">
          <a href="/" className="home-link">
            <i className="fas fa-home"></i>
          </a>
          </div>
      <LotteryGen
        lotteryType="Lucky Number Pick 3"
        states={states}
        statesWithFireball={statesWithFireball}
        numDigits={3}
      />
      <div className='books-container'>
        <LotteryBooks />
      </div>
      </>
    );
  };
export default Pick3StateGenerator;
