import React, { useState } from 'react';
import './LotteryGen.css'; // Your CSS for styling the generator

const LotteryGen = ({ lotteryType, states = [], numDigits, isRegistered }) => {
  const [selectedState, setSelectedState] = useState("");
  const [winningNumbers, setWinningNumbers] = useState([]);

  // Handle number generation
  const drawNumbers = () => {
    setWinningNumbers(
      Array.from({ length: numDigits }, () => Math.floor(Math.random() * 10))
    );
  };

  // Render Winning Numbers
  const renderWinningNumbers = () => {
    if (winningNumbers.length === 0) return null;

    return (
      <div className="lottery-grid-display-combinations-container">
        <div className="lottery-gen-grid-display-grid">
          {winningNumbers.map((num, index) => (
            <div key={index} className="lottery-grid-display-cell">{num}</div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="lottery-grid-wrapper">
      <div className="lottery-grid-display-container">
        <h1 className="lottery-grid-display-title">{lotteryType} Lottery Generator</h1>

        {states.length > 0 && (
          <div className="lottery-grid-display-input-container">
            <label className="lottery-grid-display-label">
              Select State:
              <select 
                className="lottery-grid-display-input" 
                value={selectedState} 
                onChange={(e) => setSelectedState(e.target.value)}
              >
                {states.map((state) => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </label>
          </div>
        )}

        <button 
          className="lottery-grid-display-button" 
          onClick={drawNumbers} 
          disabled={!selectedState || states.length === 0}
        >
          Generate Numbers
        </button>

        {renderWinningNumbers()}
      </div>
    </div>
  );
};

export default LotteryGen;
