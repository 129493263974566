import React, { useState, useRef } from 'react';
import './Grid.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LotteryBooks from './LotteryBooks';

const GridDisplay = () => {
  const [middayDraw, setMiddayDraw] = useState('');
  const [eveningDraw, setEveningDraw] = useState('');
  const [baseGrid, setBaseGrid] = useState(Array(4).fill(Array(4).fill(null)));
  const [translatedGrid, setTranslatedGrid] = useState(Array(4).fill(Array(4).fill(null)));
  const [baseResults, setBaseResults] = useState({});
  const [translatedResults, setTranslatedResults] = useState({});

  const eveningInputRef = useRef(null);

  const mirrorNumber = (num) => (num + 5) % 10;

  const extractResults = (grid) => {
    const diagonals = {
      leftToRight: [grid[0][0], grid[1][1], grid[2][2], grid[3][3]],
      rightToLeft: [grid[0][3], grid[1][2], grid[2][1], grid[3][0]],
    };

    const centerColumns = {
      secondColumn: [grid[0][1], grid[1][1], grid[2][1], grid[3][1]],
      thirdColumn: [grid[0][2], grid[1][2], grid[2][2], grid[3][2]],
    };

    return { diagonals, centerColumns };
  };

  const updateGrids = () => {
    if (middayDraw.length === 4 && eveningDraw.length === 4) {
      const middayNumbers = middayDraw.split('').map(Number);
      const eveningNumbers = eveningDraw.split('').map(Number);

      const newBaseGrid = [
        [middayNumbers[0], null, null, middayNumbers[1]],
        [null, eveningNumbers[0], eveningNumbers[1], null],
        [null, eveningNumbers[3], eveningNumbers[2], null],
        [middayNumbers[3], null, null, middayNumbers[2]],
      ];

      let count = (middayNumbers[0] + 1) % 10;
      const fillOrder = [
        [0, 1], [0, 2], [1, 3], [2, 3], [3, 2], [3, 1], [3, 0], [2, 0],
        [1, 0], [1, 1], [1, 2], [2, 2], [2, 1]
      ];

      fillOrder.forEach(([row, col]) => {
        if (newBaseGrid[row][col] === null) {
          newBaseGrid[row][col] = count;
          count = (count + 1) % 10;
        }
      });

      const newTranslatedGrid = newBaseGrid.map(row => row.map(num => mirrorNumber(num)));

      setBaseGrid(newBaseGrid);
      setTranslatedGrid(newTranslatedGrid);
      setBaseResults(extractResults(newBaseGrid));
      setTranslatedResults(extractResults(newTranslatedGrid));
    }
  };

  const renderGrid = (grid) => (
    <div className="lottery-grid-display-grid">
      {grid.flat().map((cell, index) => (
        <div key={index} className="lottery-grid-display-cell">
          {cell}
        </div>
      ))}
    </div>
  );

  const renderResults = (results) => (
    <div className="lottery-grid-display-results">
      <p><strong style={{ color: 'white'}}>Play 1:</strong> <strong>{results.diagonals.leftToRight.join('')}</strong></p>
      <p><strong style={{ color: 'white' }}>Play 2:</strong> <strong>{results.diagonals.rightToLeft.join('')}</strong></p>
      <p><strong style={{ color: 'white' }}>Play 3:</strong><strong> {results.centerColumns.secondColumn.join('')}</strong></p>
      <p><strong style={{ color: 'white' }}>Play 4:</strong><strong> {results.centerColumns.thirdColumn.join('')}</strong></p>
    </div>
  );

  const handleMiddayChange = (e) => {
    setMiddayDraw(e.target.value);
    if (e.target.value.length === 4) {
      eveningInputRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      updateGrids();
    }
  };

  return (
    <Tabs>
    {/* Home Link */}
    <div className="home-link-container">
          <a href="/" className="home-link">
            <i className="fas fa-home"></i>
          </a>
          </div>
      <TabList>
        <Tab>4x4 Rundown</Tab>
        <Tab>How To Use</Tab>
        <Tab>Mirrors & Flip Numbers</Tab>
        <Tab>Resources</Tab>
      </TabList>

      <TabPanel>
        <div className="lottery-grid-wrapper">
          <div className="lottery-grid-display-container">
            <h1 className="lottery-grid-display-title">4x4 Pick 4 Rundown</h1>

            <div className="lottery-grid-display-input-container">
              <label className="lottery-grid-display-label">
                Midday Draw (4 digits):
                <input
                  type="tel"
                  value={middayDraw}
                  onChange={handleMiddayChange}
                  maxLength="4"
                  className="lottery-grid-display-input"
                  onKeyDown={handleKeyDown}
                />
              </label>
              <label className="lottery-grid-display-label">
                Evening Draw (4 digits):
                <input
                  type="tel"
                  value={eveningDraw}
                  onChange={(e) => setEveningDraw(e.target.value)}
                  maxLength="4"
                  className="lottery-grid-display-input"
                  onKeyDown={handleKeyDown}
                  ref={eveningInputRef}
                />
              </label>
              <button onClick={updateGrids} className="lottery-grid-display-button">Generate Numbers</button>
            </div>

            <div className="lottery-grid-display-combinations-container">
              <div className="lottery-grid-display-grid-wrapper">
                <h2 className="lottery-grid-display-grid-title">Base Number</h2>
                {renderGrid(baseGrid)}
                {baseResults.diagonals && renderResults(baseResults)}
              </div>
              <div className="lottery-grid-display-grid-wrapper">
                <h2 className="lottery-grid-display-grid-title">Mirror Number</h2>
                {renderGrid(translatedGrid)}
                {translatedResults.diagonals && renderResults(translatedResults)}
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="how-to-use">
          <h2>How To Use the 4x4 Pick 4 Rundown Tool</h2>
          <ol>
            <li>Enter the 4-digit Midday Draw in the provided input field.</li>
            <li>Enter the 4-digit Evening Draw in the corresponding input field.</li>
            <li>Press <strong>Generate Numbers</strong> or hit <strong>Enter</strong> to create the 4x4 grids.</li>
            <li>Review the generated Base Number grid and the Mirror Number grid.</li>
            <li>Observe the results shown under each grid, which include various plays based on diagonals and column combinations.</li>
          </ol>
          <p>This tool is designed to help users generate potential winning number combinations based on the input draws.</p>
        </div>
      </TabPanel>
      <TabPanel>
          <div className="how-to-use"> {/* Reuse the auth-container class */}
            <h2>How to Use Mirror and Flip Numbers</h2>
            <p>
              Mirror and flip numbers are common strategies used in lottery systems to help you generate alternative combinations based on your original number. Here’s how they work:
            </p>
            <h3>What are Mirror Numbers?</h3>
            <p>
              A mirror number is the "opposite" of a digit. In lottery terms, the mirror of a digit is derived by adding 5 to the original number, with results modulated by 10. For example:
            </p>
            <ul className="mirror-number-list">
              <li>The mirror of <strong>0</strong> is <strong>5</strong>.</li>
              <li>The mirror of <strong>1</strong> is <strong>6</strong>.</li>
              <li>The mirror of <strong>2</strong> is <strong>7</strong>.</li>
              <li>The mirror of <strong>3</strong> is <strong>8</strong>.</li>
              <li>The mirror of <strong>4</strong> is <strong>9</strong>.</li>
              <li>The mirror of <strong>5</strong> is <strong>0</strong>.</li>
              <li>The mirror of <strong>6</strong> is <strong>1</strong>.</li>
              <li>The mirror of <strong>7</strong> is <strong>2</strong>.</li>
              <li>The mirror of <strong>8</strong> is <strong>3</strong>.</li>
              <li>The mirror of <strong>9</strong> is <strong>4</strong>.</li>
            </ul>
            <p>To use mirror numbers, simply convert each digit of your original number to its mirror. For example, if your number is 1234, the mirror version would be 6789.</p>

            <h3>What are Flip Numbers?</h3>
            <p>
              Flip numbers involve shifting each digit up by 3. For example:
            </p>
            <ul className="flip-number-list">
              <li>The flip of <strong>0</strong> is <strong>3</strong>.</li>
              <li>The flip of <strong>1</strong> is <strong>4</strong>.</li>
              <li>The flip of <strong>2</strong> is <strong>5</strong>.</li>
              <li>The flip of <strong>3</strong> is <strong>6</strong>.</li>
              <li>The flip of <strong>4</strong> is <strong>7</strong>.</li>
              <li>The flip of <strong>5</strong> is <strong>8</strong>.</li>
              <li>The flip of <strong>6</strong> is <strong>9</strong>.</li>
              <li>The flip of <strong>7</strong> is <strong>0</strong>.</li>
              <li>The flip of <strong>8</strong> is <strong>1</strong>.</li>
              <li>The flip of <strong>9</strong> is <strong>2</strong>.</li>
            </ul>
            <p>
              Using flip numbers helps generate a new set of combinations by transforming each digit of your original number. For example, if your number is 2468, the flip version would be 5791.
            </p>

            <h3>When to Use Mirror and Flip Numbers</h3>
            <p>
              You can use mirror and flip numbers when you want to increase your chances of hitting a winning combination by covering more number patterns. Both techniques generate variations of your original number to help you explore different possibilities.
            </p>
            <p>
              Try using these techniques next time you generate a combination to see how they work!
            </p>
          </div>
        </TabPanel>
        <TabPanel>
              <LotteryBooks />
        </TabPanel>
      
    </Tabs>
  );
};

export default GridDisplay;
