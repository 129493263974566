import React from 'react';
import { createRoot } from 'react-dom/client'; // Import from react-dom/client
import './index.css';
import App from './App';
import { AuthProvider } from './components/firebase/authContext'; // Auth context
import { HelmetProvider } from 'react-helmet-async'; // Correct import

// Find the root DOM element where the app will be rendered
const container = document.getElementById('root');
const root = createRoot(container); // Create a root using createRoot()

// Render the App component
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  </HelmetProvider>
);
