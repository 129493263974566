import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from './firebase/firebaseConfig'; // Assuming you have Firestore connected
import { doc, setDoc } from 'firebase/firestore';
import './Auth.css'; // Reusing the styles

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Log error to Firestore
  const logError = async (errorMessage) => {
    try {
      const errorLogRef = doc(db, 'errorLogs', Date.now().toString()); // Use timestamp as document ID
      await setDoc(errorLogRef, {
        email,
        error: errorMessage,
        timestamp: new Date().toISOString(),
      });
      console.log('Error logged to Firestore.');
    } catch (err) {
      console.error('Failed to log error: ', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // Redirect to the home page (Pick4Generator) on successful login
    } catch (err) {
      const errorMessage = 'Login failed. Please check your credentials and try again.';
      setError(errorMessage);
      await logError(errorMessage + ' - ' + err.message); // Log the error
    }
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (err) {
      let errorMessage = 'Google sign-in failed. Please try again.';
      if (err.code === 'auth/popup-closed-by-user') {
        errorMessage = 'Google sign-in window was closed. Please try again.';
      }
      setError(errorMessage);
      await logError(errorMessage + ' - ' + err.message); // Log the error to Firestore
    }
  };

  return (
    <div className="auth-container">
      <h2>Login</h2>

      {/* Show error messages */}
      {error && <div className="error-banner">{error}</div>}

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Sign in</button>
      </form>
      <p>
        or
      </p>

      <button className="google-signin-btn" onClick={handleGoogleSignIn}>
        Sign in with Google
      </button>
    
      <p>
        <span className="link-text" onClick={() => navigate('/reset-password')}>
          Forgot password?
        </span>
      </p>

      <p className="footer-text">
        Don't have an account?{' '}
        <button className="link-button" onClick={() => navigate('/signup')}>
          Sign up
        </button>
      </p>
    </div>
  );
}

export default Login;
