import React from 'react';
import './PrivacyPolicy.css'; // Create a CSS file for styling if needed

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <p>
        At Pick4Generator, we respect your privacy and are committed to protecting the personal information you share with us. This policy outlines how we collect, use, and safeguard your information.
      </p>

      <h2>Information We Collect</h2>
      <p>
        When you use our services, we may collect the following types of information:
      </p>
      <ul>
        <li><strong>Personal Information:</strong> Name, email address, login credentials, and other similar information when you sign up or use our service.</li>
        <li><strong>Usage Data:</strong> We may collect information about how you access and use the service, such as your IP address, browser type, and device information.</li>
        <li><strong>Cookies:</strong> We use cookies to enhance your experience and for analytical purposes.</li>
      </ul>

      <h2>How We Use Your Information</h2>
      <p>
        We use the information we collect for the following purposes:
      </p>
      <ul>
        <li>To provide and maintain our service.</li>
        <li>To notify you about changes to our service.</li>
        <li>To provide customer support.</li>
        <li>To monitor usage and improve the service.</li>
        <li>To detect, prevent, and address technical issues.</li>
      </ul>

      <h2>Sharing of Information</h2>
      <p>
        We do not share your personal information with third parties, except under the following circumstances:
      </p>
      <ul>
        <li>With your consent.</li>
        <li>To comply with legal obligations.</li>
        <li>To protect and defend the rights or property of Pick4Generator.</li>
        <li>To prevent fraud or investigate misconduct in connection with the service.</li>
      </ul>

      <h2>Security of Your Information</h2>
      <p>
        We use reasonable administrative, technical, and physical measures to protect your information from unauthorized access, use, and disclosure. However, no system is 100% secure, and we cannot guarantee the absolute security of your data.
      </p>

      <h2>Changes to This Privacy Policy</h2>
      <p>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please contact us at:
      </p>
      <p>Email: support@lnumerislotterystrategies.com</p>
    </div>
  );
};

export default PrivacyPolicy;
