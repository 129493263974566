import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { auth, db, googleProvider} from './firebase/firebaseConfig'; // Import providers
import { setDoc, doc } from 'firebase/firestore';
import './Auth.css'; // Same CSS file as Login

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState(''); // New state for password confirmation
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Function for signing up with Email and Password
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      // Store the user information in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        credits: 10, // Set initial credits for the new user
      });

      // Redirect to the home page after successful signup
      navigate('/');
    } catch (err) {
      setError('Sign up failed: ' + err.message);
    }
  };

  // Function to handle Google sign-up
  const handleGoogleSignUp = async () => {
    try {
      const { user } = await signInWithPopup(auth, googleProvider);
      // Store the user information in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        credits: 10, // Set initial credits for the new user
      });

      // Redirect to home page after successful signup
      navigate('/');
    } catch (err) {
      setError('Google sign-up failed: ' + err.message);
    }
  };

  // Function to handle Facebook sign-up
//   const handleFacebookSignUp = async () => {
//     try {
//       const { user } = await signInWithPopup(auth, facebookProvider);
//       // Store the user information in Firestore
//       await setDoc(doc(db, 'users', user.uid), {
//         email: user.email,
//         credits: 10, // Set initial credits for the new user
//       });

//       // Redirect to home page after successful signup
//       navigate('/');
//     } catch (err) {
//       setError('Facebook sign-up failed: ' + err.message);
//     }
//   };

  return (
    <div className="auth-container">
      <h2>Create an account</h2>
      <p>Please enter your details to sign up</p>

      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword} // Controlled input for password confirmation
          onChange={(e) => setConfirmPassword(e.target.value)} // Update confirmPassword state
          required
        />
        <button type="submit">Sign up</button>
        {error && <p className="error-message">{error}</p>}
      </form>

      <p className="footer-text">Or sign up using:</p>
      <button className="oauth-button google-signup" onClick={handleGoogleSignUp}>Sign up with Google</button>
      {/* <button className="oauth-button facebook-signup" onClick={handleFacebookSignUp}>Sign up with Facebook</button> */}

      <p className="footer-text">
        Already have an account?{' '}
        <button className="link-button" onClick={() => navigate('/login')}>
          Sign in
        </button>
      </p>
    </div>
  );
}

export default SignUp;
