import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig'; // Make sure Firebase is configured correctly
import './Auth.css'; // Use the same CSS as the login screen for consistent design

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Check your inbox.');
      setError(''); // Clear any previous errors
    } catch (err) {
      const errorMessage = 'Error: ' + err.message;
      setError(errorMessage);
      setMessage('');
    }
  };

  return (
    <div className="auth-container">
      <h2>Reset Password</h2>
      {error && <div className="error-banner">{error}</div>}
      {message && <div className="success-message">{message}</div>}

      <form onSubmit={handlePasswordReset}>
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Send Reset Email</button>
      </form>

      <p className="footer-text">
        
        <button className="link-button" onClick={() => window.history.back()}>
          Go back to login
        </button>
      </p>
    </div>
  );
}

export default ResetPassword;
