import React, { useState, useEffect } from 'react';
import './SevenThirtyRundownMethod.css';
import LogEntries from './LogEntries'; // Import the LogEntries component


const SevenThirtyRundownMethod = () => {
  const [date, setDate] = useState('');
  const [daySum, setDaySum] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [dayInputs, setDayInputs] = useState({ part1: '', part2: '', part3: '' });
  const [niteInputs, setNiteInputs] = useState({ part1: '', part2: '', part3: '' });
  const [hitSum, setHitSum] = useState('');
  const [isDayActive, setIsDayActive] = useState(null); // Both active by default
  const [logEntries, setLogEntries] = useState([]); // To log day and night entries

  const mirrorMapping = {
    0: 5, 1: 6, 2: 7, 3: 8, 4: 9,
    5: 0, 6: 1, 7: 2, 8: 3, 9: 4
  };

  const handleDateChange = (e) => {
    let dateValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (dateValue.length > 2) {
      dateValue = dateValue.slice(0, 2) + '/' + dateValue.slice(2); // Add '/' after the month
    }
    if (dateValue.length > 5) {
      dateValue = dateValue.slice(0, 5); // Limit to MM/DD format
    }
    setDate(dateValue);

    // Validate the month and day input
    if (dateValue.length === 5) {
      const [month, day] = dateValue.split('/').map(Number);

      const daysInMonth = (month) => {
        return month === 2
          ? (new Date().getFullYear() % 4 === 0 && (new Date().getFullYear() % 100 !== 0 || new Date().getFullYear() % 400 === 0))
            ? 29
            : 28
          : [4, 6, 9, 11].includes(month)
          ? 30
          : 31;
      };

      if (!isNaN(month) && !isNaN(day) && month >= 1 && month <= 12 && day >= 1 && day <= daysInMonth(month)) {
        const sum = month + day;
        const daySumDigit = sum % 10; // Keep only the last digit
        setDaySum(daySumDigit);
      } else {
        setDaySum('');
        setShowModal(true); // Show the modal
        setDate(''); // Reset the input
      }
    } else {
      setDaySum('');
    }
  };

  const handleInputChange = (e, part, setInputs, nextInputId) => {
    const value = e.target.value.replace(/[^0-9]/g, ''); // Only allow numbers
    setInputs((prev) => ({ ...prev, [part]: value }));

    // Automatically focus on the next input if there is a next input and the current input is filled
    if (value && nextInputId) {
      document.getElementById(nextInputId)?.focus();
    }

    // Set isDayActive based on which input is being populated
    if (setInputs === setDayInputs && Object.values({ ...dayInputs, [part]: value }).some(val => val !== '')) {
      setIsDayActive(true);
    } else if (setInputs === setNiteInputs && Object.values({ ...niteInputs, [part]: value }).some(val => val !== '')) {
      setIsDayActive(false);
    } else if (Object.values(dayInputs).every(val => val === '') && Object.values(niteInputs).every(val => val === '')) {
      setIsDayActive(null); // Re-enable both if all inputs are cleared
    }
  };

  const clearInputs = (setInputs, type) => {
    setInputs({ part1: '', part2: '', part3: '' });
    if (type === 'day' || type === 'nite') {
      setIsDayActive(null); // Re-enable both inputs when either day or nite is cleared
    }
  };

  useEffect(() => {
    const calculateHitSum = () => {
      const daySum = Object.values(dayInputs).reduce((sum, val) => sum + (parseInt(val) || 0), 0);
      const niteSum = Object.values(niteInputs).reduce((sum, val) => sum + (parseInt(val) || 0), 0);
      const totalSum = daySum > 0 ? daySum : niteSum;
      const splitSum = totalSum.toString().split('').reduce((sum, num) => sum + parseInt(num, 10), 0) % 10; // Keep only the last digit
      setHitSum(splitSum);
    };

    calculateHitSum();
  }, [dayInputs, niteInputs]);

  useEffect(() => {
    logDayAndNightSum();
  }, [dayInputs, niteInputs]);

  const logDayAndNightSum = () => {
    const daySumValue = `${dayInputs.part1}${dayInputs.part2}${dayInputs.part3}`.replace(/[^0-9]/g, '');
    const niteSumValue = `${niteInputs.part1}${niteInputs.part2}${niteInputs.part3}`.replace(/[^0-9]/g, '');

    // Only log when full inputs are provided for either day or night
    if (daySumValue.length === 3 || niteSumValue.length === 3) {
      setLogEntries((prevEntries) => [
        ...prevEntries,
        {
          daySum: daySumValue || '0',
          niteSum: niteSumValue || '0',
          date: new Date().toLocaleDateString(), // Log only the date without time
        }
      ]);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getMirrorValue = (value) => {
    return value !== '' ? mirrorMapping[value] : '';
  };

  const getCellClassName = (num) => {
    const mirrorHitSum = parseInt(getMirrorValue(hitSum), 10);
    const mirrorHitSumPlusOne = parseInt(getMirrorValue((hitSum + 1) % 10), 10);
    const hitSumPlusOne = (hitSum + 1) % 10;

    if (num === daySum) {
      return 'grid-cell highlighted-cell';
    } else if (num === hitSum || num === hitSumPlusOne) {
      return 'grid-cell highlighted-hit-sum';
    } else if (num === mirrorHitSum || num === mirrorHitSumPlusOne) {
      return 'grid-cell highlighted-mirror';
    }
    return 'grid-cell';
  };

  return (
    <>
      {/* Home Link */}
      <div className="home-link-container">
          <a href="/" className="home-link">
            <i className="fas fa-home"></i>
          </a>
          </div>
      <h1 className="title">730 RUNDOWN PICK 3 DRAW</h1>
      <div className="container">
        {showModal && (
          <div className="modal-overlay">
            <div className="modal">
              <p>Invalid date entered. Please check the month and day.</p>
              <button onClick={closeModal}>OK</button>
            </div>
          </div>
        )}
        <div className="date-sum-header">
          <div className="date-input">
            <label className="label">DATE</label>
            <input
              type="text"
              className="input date-field"
              placeholder="MM/DD"
              value={date}
              onChange={handleDateChange}
            />
          </div>
          <div className="sum-circle">
            <label className="label">SUM</label>
            <div className="circle highlighted-cell">{daySum}</div>
          </div>
        </div>

        <div className="grid-section">
          <table className="number-grid">
            <tbody>
              {[
                [7, 3, 0],
                [8, 4, 1],
                [9, 5, 2],
                [0, 6, 3],
                [1, 7, 4],
                [2, 8, 5]
              ].map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((num, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={getCellClassName(num)}
                      style={
                        num === hitSum || num === (hitSum + 1) % 10 || num === parseInt(getMirrorValue(hitSum), 10) || num === parseInt(getMirrorValue((hitSum + 1) % 10), 10)
                          ? { backgroundColor: 'greenyellow' }
                          : {}
                      }
                    >
                      {num}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="day-nite-section">
          <div className="day-input">
            <label className="label">DAY</label>
            <div className="day-inputs">
              <input
                type="text"
                maxLength="1"
                className="input day-part"
                placeholder="D"
                id="day-part1"
                value={dayInputs.part1}
                onChange={(e) => handleInputChange(e, 'part1', setDayInputs, 'day-part2')}
                disabled={isDayActive === false && !Object.values(dayInputs).some(val => val !== '')}
              />
              <input
                type="text"
                maxLength="1"
                className="input day-part"
                placeholder="D"
                id="day-part2"
                value={dayInputs.part2}
                onChange={(e) => handleInputChange(e, 'part2', setDayInputs, 'day-part3')}
                disabled={isDayActive === false && !Object.values(dayInputs).some(val => val !== '')}
              />
              <input
                type="text"
                maxLength="1"
                className="input day-part"
                placeholder="D"
                id="day-part3"
                value={dayInputs.part3}
                onChange={(e) => handleInputChange(e, 'part3', setDayInputs, null)}
                disabled={isDayActive === false && !Object.values(dayInputs).some(val => val !== '')}
              />
              <button onClick={() => clearInputs(setDayInputs, 'day')}>Clear</button>
            </div>
            {Object.values(dayInputs).some(val => val !== '') && (
              <div className="calculation-label">
                <label className="label">DAY CALCULATION</label>
                <div className="calculation-value">
                  {`${Object.values(dayInputs).map(val => val || 0).join(' + ')} = ${Object.values(dayInputs).reduce((sum, val) => sum + (parseInt(val) || 0), 0)}`}
                </div>
              </div>
            )}
          </div>
          <div className="nite-input">
            <label className="label">NITE</label>
            <div className="nite-inputs">
              <input
                type="text"
                maxLength="1"
                className="input nite-part"
                placeholder="N"
                id="nite-part1"
                value={niteInputs.part1}
                onChange={(e) => handleInputChange(e, 'part1', setNiteInputs, 'nite-part2')}
                disabled={isDayActive === true && !Object.values(niteInputs).some(val => val !== '')}
              />
              <input
                type="text"
                maxLength="1"
                className="input nite-part"
                placeholder="N"
                id="nite-part2"
                value={niteInputs.part2}
                onChange={(e) => handleInputChange(e, 'part2', setNiteInputs, 'nite-part3')}
                disabled={isDayActive === true && !Object.values(niteInputs).some(val => val !== '')}
              />
              <input
                type="text"
                maxLength="1"
                className="input nite-part"
                placeholder="N"
                id="nite-part3"
                value={niteInputs.part3}
                onChange={(e) => handleInputChange(e, 'part3', setNiteInputs, null)}
                disabled={isDayActive === true && !Object.values(niteInputs).some(val => val !== '')}
              />
              <button onClick={() => clearInputs(setNiteInputs, 'nite')}>Clear</button>
            </div>
            {Object.values(niteInputs).some(val => val !== '') && (
              <div className="calculation-label">
                <label className="label">NITE CALCULATION</label>
                <div className="calculation-value">
                  {`${Object.values(niteInputs).map(val => val || 0).join(' + ')} = ${Object.values(niteInputs).reduce((sum, val) => sum + (parseInt(val) || 0), 0)}`}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="hit-sum-section">
          <label className="label">HIT SUM</label>
          <div className="hit-sum-wrapper">
            <input type="text" className="input hit-sum-field highlighted-cell" value={hitSum} readOnly style={{ width: '40px', height: '40px', backgroundColor: 'greenyellow' }} />
            <input type="text" className="input hit-sum-plus-one-field highlighted-cell" value={hitSum !== '' ? (hitSum + 1) % 10 : ''} readOnly style={{ width: '40px', height: '40px', marginLeft: '10px', backgroundColor: 'greenyellow' }} />
          </div>
          <div className="mirror-wrapper">
            <input type="text" className="input mirror-hit-sum-field highlighted-cell" value={getMirrorValue(hitSum)} readOnly style={{ width: '40px', height: '40px', marginTop: '10px', backgroundColor: 'greenyellow' }} />
            <input type="text" className="input mirror-hit-sum-plus-one-field highlighted-cell" value={hitSum !== '' ? getMirrorValue((hitSum + 1) % 10) : ''} readOnly style={{ width: '40px', height: '40px', marginLeft: '10px', marginTop: '10px', backgroundColor: 'greenyellow' }} />
          </div>
        </div>

        <div className="mirror-section">
          <label className="label">MIRROR</label>
          <div className="mirror-grid">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
              <div key={num} className={`mirror-cell ${num === hitSum || num === parseInt(getMirrorValue(hitSum)) || num === (hitSum + 1) % 10 || num === parseInt(getMirrorValue((hitSum + 1) % 10)) ? 'highlighted-cell' : ''}`}>
                {num}
              </div>
            ))}
          </div>
        </div>
      </div>

      <LogEntries logEntries={logEntries} />

      <div className="about-section">
                    <h2 style={{textAlign:'center'}}>Companion Book</h2>
                    <p>The <strong>Pick 3 Lottery Book 730 Rundown Method Strategy Workbook</strong> provides an easy-to-follow system to track lottery numbers, allowing you to increase your chances of winning. With a strategic approach tailored for your state lottery, this book will guide you through the rundown method to improve your odds.</p>
                    <img
                        src="https://m.media-amazon.com/images/I/41E8GuhqNRL._SY445_SX342_.jpg"
                        alt="Pick 3 Lottery Book 730 Rundown Method"
                        style={{ width: '50%', margin: '20px 0', display: 'block', margin: '0 auto' }}

                    />
                    <h2>What's Inside?</h2>
                    <ul>
                        <li><strong>730 Rundown Strategy:</strong> A proven method to help track and predict lottery numbers.</li>
                        <li><strong>State Lottery Focused:</strong> Learn strategies that are tailored to the rules of your state lottery.</li>
                        <li><strong>Number Tracker:</strong> Detailed pages for tracking your chosen numbers and results over time.</li>
                        <li><strong>Boost Your Winning Odds:</strong> Use strategic methods to improve your chances of winning the Pick 3 lottery.</li>
                    </ul>
                    <p>This workbook is designed for anyone who plays the Pick 3 lottery and wants to develop a more calculated and strategic approach to increase their odds of winning.</p>
                </div>
                <div className="cta-section">
                    <h2>Start Improving Your Chances of Winning Today</h2>
                    <p>Order the <strong>Pick 3 Lottery Book: 730 Rundown Method Strategy Workbook</strong> and take your lottery game to the next level.</p>
                    <a
                        href="https://a.co/d/0hK1m4JV"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-button"
                    >
                        Buy Now
                    </a>
                </div>
      
    </>
  );
};

export default SevenThirtyRundownMethod;
